<template>
    <page title="Lançamentos Futuros">
        <v-breadcrumbs slot="breadcrumb" class="pa-0">
            <v-breadcrumbs-item exact :to="{name:'dashboard'}">Início</v-breadcrumbs-item>
            <v-breadcrumbs-divider>></v-breadcrumbs-divider>
            <v-breadcrumbs-item exact :to="{name:'financeiro'}">Financeiro</v-breadcrumbs-item>
            <v-breadcrumbs-divider>></v-breadcrumbs-divider>
            <v-breadcrumbs-item exact :to="{name:'conta'}">Conta</v-breadcrumbs-item>
        </v-breadcrumbs>

        <div>
            <filter-list-fragment v-model="currentFilter" @input="onFilterChanged" />

            <div class="mt-2" v-if="totalCount > 0">
                <span class="subtitle-2 font-weight-regular">{{totalCount}} resultados encontrados</span>
            </div>

            <div class="text-center" v-if="transacoes">
                <v-pagination v-model="page" total-visible="8"
                              :length="Math.ceil(totalCount / offset)"
                              @input="pageChanged"></v-pagination>
            </div>
            <v-card class="mt-3">
                <v-simple-table dense v-if="transacoes && transacoes.length > 0">
                    <template v-slot:default>
                        <thead>
                        <tr style="white-space: nowrap">
                            <th class="text-left">Data</th>
                            <th class="text-left">Lançamento</th>
                            <th class="text-left">Dcto.</th>
                            <th class="text-left" >Valor</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(transacao, index) in transacoes">
                            <tr :key="transacao.id" :class="transacao.ignored ? 'ignored' : ''" style="white-space: nowrap">
                                <td class="grey--text darken-3 caption">
                                    <span>{{ transacao.dtposted | moment("DD/MM/YYYY") }}</span>
                                </td>
                                <td class="caption" style="width: 100%"><span v-html="highlight(transacao.memo, currentFilter.searchValue)"></span>
                                </td>
                                <td class="caption">{{transacao.checknum}}</td>
                                <td :class="(transacao.trnamt > 0 ? 'indigo--text' : 'red--text')+' caption'">
                                    <span>{{transacao.trnamt | formatNumber}}</span>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </template>
                </v-simple-table>
                <div v-else-if="loading === false && (transacoes === null || transacoes.length === 0)" class="text-center pa-6">
                    <h1 class="display-3 mb-4">Ops!</h1>
                    <span class="headline font-weight-light">Nenhum resultado encontrado!</span>
                </div>
                <v-overlay :value="loading" color="white" absolute opacity="0.8" z-index="1" />
                <v-progress-linear :active="loading" :indeterminate="loading" absolute top color="secondary" />
            </v-card>
            <div class="text-center mt-3" v-if="transacoes">
                <v-pagination v-model="page" total-visible="8"
                              :length="Math.ceil(totalCount / offset)"
                              @input="pageChanged"></v-pagination>
            </div>
        </div>
        <vincular-movimentacao-dialog ref="vincularMovimentacaoDialog" />
        <view-movimentacao-dialog ref="viewMovimentacaoDialog" />
    </page>
</template>

<script>
import page from "@/components/Page";
import gql from "graphql-tag";
import {getExtensionFile} from "@/assets/FileUtils";
import FilterListFragment from "@/views/financeiro/conta/fragments/filter/FilterListFragment";
import localforage from "localforage";
import {BooleanData} from "@/models/filter/BooleanData";
import {DateData} from "@/models/filter/DateData";
import {SortData} from "@/models/filter/SortData";
import VincularMovimentacaoDialog from "@/views/financeiro/conta/VincularMovimentacaoDialog";
import ViewMovimentacaoDialog from "@/views/financeiro/ViewMovimentacaoDialog";
import {IdData} from "@/models/filter/IdData";

export default {
    name: "Index",
    components: {
        VincularMovimentacaoDialog,
        page,
        FilterListFragment,
        ViewMovimentacaoDialog,
    },
    data() {
        return {
            loading: true,
            offset: 30,
            page: 1,
            totalCount: 0,
            transacoes: [],
            currentFilter: {itens:[]},
        }
    },
    watch: {
        $route(to, from) {
            if(to.query.page === undefined){
                this.$router.push({query:{page: '1'}});
                return;
            }
            this.page = parseInt(to.query.page);
            if(to.query.page === from.query.page){
                return
            }

            this.getList(this.currentFilter)
        }
    },
    methods: {
        pageChanged(value){
            this.$router.push({query:{page:this.page}});
        },
        getList(filter){
            this.loading = true;
            let filterFormatted = this.formatFilterToRequest(filter);
            filterFormatted.itens.push({name: "lancamento-futuro", "booleanData": {"status": true}})

            this.$apollo.query({
                query: gql`query($filter: SearchFilterInput!, $page: Int, $offset: Int){
                    searchOfxTransactions(filter: $filter, page: $page, offset: $offset){
                        totalCount
                        transacoes:itens{
                            id
                            dtposted
                            memo
                            trnamt
                            trntype
                            checknum
                            ignored
                            notaFiscal {
                                id
                            }
                            movimentacaoPagamento{
                                id
                                movimentacao{
                                    id
                                }
                            }
                        }
                    }
                }`,
                variables: {
                    filter: filterFormatted,
                    page: this.page - 1,
                    offset: this.offset
                },
            }).then(result => {
                this.transacoes = result.data.searchOfxTransactions.transacoes;
                this.totalCount = result.data.searchOfxTransactions.totalCount;
            }).finally(() => {
                this.loading = false;
            })
        },
        criaLinkReferencia(transacao){
            if(transacao.movimentacaoPagamento){
                return {path: 'movimentacao/' + transacao.movimentacaoPagamento.movimentacao.id}
            }
            /*if(transacao.notaFiscal){
                return {name:'nota_fiscal_view', params: {id:transacao.notaFiscal.id}}
            }*/
            return null
        },
        onFilterChanged(filter) {
            this.currentFilter = filter;
            this.saveFilter(filter);
            if(this.$route.query.page == 1){
                this.getList(this.currentFilter);
                return
            }
            this.$router.replace({query:{page:1}});
        },
        formatFilterToRequest(filter) {
            const parsedFilter = this._.cloneDeep(filter);

            if (!parsedFilter.searchValue) {
                parsedFilter.searchCriteria = null;
            }

            if (filter.itens) {
                parsedFilter.itens = filter.itens.map((filterItem) => {
                    const filterItemMapped = {
                        name: filterItem.name,
                    };

                    switch (filterItem.type) {
                        case 'boolean':
                            filterItemMapped.booleanData = this._.cloneDeep(filterItem.data);
                            delete filterItemMapped.booleanData.trueLabel;
                            delete filterItemMapped.booleanData.falseLabel;
                            delete filterItemMapped.booleanData.toLabel;

                            break;
                        case 'sort':
                            filterItemMapped.sortData = this._.cloneDeep(filterItem.data);
                            filterItemMapped.sortData.fieldName = filterItemMapped.sortData.field.name;
                            delete filterItemMapped.sortData.field;
                            delete filterItemMapped.sortData.toLabel;
                            break;
                        case 'date':
                            filterItemMapped.dateData = this._.cloneDeep(filterItem.data);
                            delete filterItemMapped.dateData.rangeType;
                            delete filterItemMapped.dateData.toLabel;
                            break;
                        case 'tipo':
                            filterItemMapped.idData = this._.cloneDeep(filterItem.data);
                            delete filterItemMapped.idData.nome;
                            delete filterItemMapped.idData.toLabel;
                            break;
                    }

                    return filterItemMapped;
                });
            }

            return parsedFilter;
        },
        saveFilter(filter) {
            const filterParsed = this._.cloneDeep(filter);
            filterParsed.searchCriteria = null;
            filterParsed.searchValue = null;

            localforage.setItem('transacoes_futuras_filter', filterParsed);
        },
        async loadFilter() {
            const savedFilter = await localforage.getItem('transacoes_futuras_filter');
            if (savedFilter) {
                savedFilter.itens.map((filterItem) => {
                    switch (filterItem.type) {
                        case 'boolean':
                            filterItem.data = new BooleanData(
                                filterItem.data.status, filterItem.data.trueLabel, filterItem.data.falseLabel);
                            break;
                        case 'date':
                            filterItem.data = new DateData(filterItem.data.before, filterItem.data.after, filterItem.data.rangeType);
                            break;
                        case 'sort':
                            filterItem.data = new SortData(
                                filterItem.data.field.label, filterItem.data.field.name, filterItem.data.ascending);
                            break;
                        case 'tipo':
                            filterItem.data = new IdData(filterItem.data.id, filterItem.data.nome);
                            break;
                    }
                    return filterItem;
                });
            }
            return {itens:[]};
        },
        highlight: function(words, query){
            if(query === ''){
                return words;
            }
            return words.toString().replace(new RegExp(query, "gi"), matchedTxt => {
                return '<span class="highlight">' + matchedTxt + '</span>';
            });
        },
        desvincularMovimentacao(transacao){
            this.$dialog.confirm({
                text: 'Tem certeza que deseja desvincular a movimentação dessa transação?',
                title: 'Atenção',
                actions: {
                    false: 'Cancelar', yes: {text: 'Sim', color: 'primary',},
                }
            }).then((result) => {
                if(result){
                    this.$apollo.mutate({
                        mutation: gql`mutation($transacaoId: Int!) {
                            removeMovimentacaoDaTransacao(transacaoId: $transacaoId)
                        }`,
                        variables: {
                            transacaoId: transacao.id
                        },
                    }).then((result) => {
                        if(result.data.removeMovimentacaoDaTransacao){
                            transacao.movimentacaoPagamento = null;
                        }
                    })
                }

            })
        },
        vincularMovimentacao(transacao){
            this.$refs.vincularMovimentacaoDialog.openDialog(transacao)
        },
        ignorarTransacao(transacao, valor){
            this.$apollo.mutate({
                mutation: gql`mutation($id: Int!, $valor: Boolean!) {
                    ignorarTransacao(id: $id, valor: $valor)
                }`,
                variables: {
                    id: transacao.id,
                    valor: valor
                },
            }).then((result) => {
                if(result.data.ignorarTransacao){
                    transacao.ignored = valor;
                }
            })
        },
        viewMovimentacao(id){
            this.$refs.viewMovimentacaoDialog.openDialog(id);
        },
    },
    mounted() {
        document.title = "Lançamentos Futuros - JF Assessoria Rural"
        this.loadFilter().then((filter) => {
            if(!this.$route.query.page){
                this.$router.replace({query:{page:this.page}});
                return;
            }else{
                this.page = parseInt(this.$route.query.page);
            }
            if (filter) {
                // TODO Montar o componente do filtro aqui
                this.currentFilter = filter;
                this.getList(this.currentFilter);
            } else {
                this.getList(this.currentFilter);
            }
        })
    }
}
</script>

<style>
span.highlight {
    background-color: yellow;
}
</style>
<style scoped>
    .v-data-table tr.ignored{
        opacity: 0.5;
        background: #eeee
    }

    .v-data-table tr.ignored td>span{
        text-decoration: line-through;
    }

    .v-data-table tr.ignored:hover{
    }
</style>